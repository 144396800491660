.Table {
  padding: 20px 20px;
}

.MoreButtonChildContainer {
  position: absolute;
  left: -7rem;
  padding: 1rem;
  z-index: 2;
}

.DeleteRow {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: .2rem .5rem .2rem .5rem;
}

.DeleteRow:hover {
  background: #aab8c2;
}

.ExpanderOpen {
  transform: rotate(180deg);
}
