.DropdownMenu {
  position: relative;
  top: 0%;
  width: 100%;
}

.DropdownMenu > div {
  display: inline-block;
}

.NameText {
  margin-bottom: 10px;
}

.Selector {
  width: 100%;
  top: 0%;
  font-size: 14px;
  letter-spacing: 1px;
  position: relative;
  color: black;
}

.ErrorText {
  color: red;
  position: absolute;
  width: 30rem;
  font-size: 16px;
  letter-spacing: 0.9px;
}