html, body {
    margin: 0;
    padding: 0;
    font-family: 'Inter-Regular';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: 1px;
    color: #ffffff;
    background-color: #379CF0;
}
body {
    overflow-x: hidden;
}