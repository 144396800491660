/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2018 Colophon Foundry
 *
 * Licenced to Barona Oy Barona Oy
 */

@font-face {
    font-family: 'value-black';
    src: url('value-black.eot');
    src: url('value-black.eot?#iefix') format('embedded-opentype'),
         url('value-black.woff') format('woff'),
         url('value-black.woff2') format('woff2'),
         url('value-black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Inter-Bold';
    src: url('Inter-Bold.ttf');
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('Inter-Regular.ttf');
}
