.Table {
  overflow-x: visible;
  overflow-y: visible;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.TableHeaders {
  margin: 5px 15px 5px 15px;
  text-align: start;
  height: 65px;
  font-weight: 600;
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: black;
  padding: 15px 30px;
  /* min-height: 72px; */
}

.TableRow {
  margin: 5px 15px 5px 15px;
  text-align: start;
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: black;
  padding: 15px 30px;
  /* min-height: 72px; */
}

.Filter {
  background-color: #379CF0;
  margin: 0 15px 0 15px;
}
