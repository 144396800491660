.Table .rt-thead .rt-th {
  border-right: none;
  outline: none;
}

.Table .rt-tbody {
  border-right: none;
  padding: 10px 0 10px 0px;
  text-align: start;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rt-td {
  text-overflow: ellipsis;
  overflow: visible;
  display: flex;

}
.tooltip {
  position: relative;
  display: block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 100;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.rt-td-element {
  padding: 10px 0 10px 0px;
  margin: auto;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  border-right: none;
}

.Table .rt-tbody,
.Table .rt-table  {
  overflow: visible;

}

.Table .rt-thead .rt-tr {
  text-align: start;
}

.Table .rt-thead .rt-th.-cursor-pointer {
  padding: 0px;
}

.Table .rt-thead .rt-th {
  padding: 0px;
}

.Table .rt-noData {
  top: 150%;
  z-index: -10;
}

.Table .rt-thead .rt-th.-sort-desc, .Table .rt-thead .rt-td.-sort-desc {
  box-shadow: 0 3px 0 0 #ffffff;
}

.Table .rt-thead .rt-th.-sort-asc, .Table .rt-thead .rt-td.-sort-asc {
  box-shadow: 0 -3px 0 0 #ffffff;
}

